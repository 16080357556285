import React,{ useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import {graphql} from "gatsby"
import Card from "../components/Card"
import {Link} from "gatsby"
import * as queryString from "query-string";
import SearchResultHeader from "../components/SearchResultHeader"
import Axios from "axios"
import Loading from "../components/Loading"
import {getNowYMDhmsStr} from "../functions"
const now = getNowYMDhmsStr();
export const query = graphql`
query  {
  allContentfulNeta(limit: 10000,
  ) {
    edges {
      node {
        slug
        id
        title
        createdAt
        createdAtJP:createdAt(formatString:"YYYY/MM/DD")
        publishStartJP:publishStart(formatString:"YYYY/MM/DD")
        publishStart
        tags {
          id
          title
          slug
        }
        childContentfulNetaContentTextNode {
          content
        }
      }
    }
  }
}
`;

let searchFlag = false;


export default ({data,location}) => {

  const [matchData,setMatchData] = useState([]);
  const [loading,setLoading] = useState(true);

  const {q} = queryString.parse(location.search);
  const keywords = q;


  if(searchFlag === false || matchData.length===0) {
    console.log('Axios.get("/page-data/search/page-data.json")');
    Axios.get("/page-data/search/page-data.json")
      .then(result => {

        function search(edges,keyword){
          let _matchData1 = edges.filter(({node}) => {
            if(node.publishStart > now) return false;

            const text = node.title
            return text.indexOf(keyword) !== -1;
          });
          let slugs = [];
          _matchData1.map(item=>{
            slugs.push(item.node.slug);
          });

          let _matchData2 = edges.filter(({node}) => {
            if(node.publishStart > now) return false;

            const text = node.childContentfulNetaContentTextNode.content;
            let match = text.indexOf(keyword) !== -1;
            match &= slugs.indexOf(node.slug) === -1;

            return match;
          });

          return _matchData1.concat(_matchData2)
        }

        let matchData = result.data.result.data.allContentfulNeta.edges

        let arr = keywords.replace(/　/g," ").split(" ");
        arr.map((keyword)=>{
          if(keyword){
            matchData = search(matchData,keyword)
          }
        })


        setMatchData(matchData);
        searchFlag = true;
        setLoading(false);
      })
      .catch(err => {
        console.log(err)
        setLoading(false);
      });
  }


  // matchData = data.allContentfulNeta.edges.filter(({node})=>{
  //   const text = node.title + node.childContentfulNetaContentTextNode.content;
  //   return text.indexOf(keyword) !== -1;
  // });
  // console.log(matchData)

 const title = `"${keywords}" の検索一覧 |【朝礼ネタ】3分間スピーチの例文集`;



  return (
    <Layout>

      <Loading show={loading} message="検索中..."/>

      <SEO
        title={title}
        pathname={location.pathname}
      />

      <SearchResultHeader
        text={`【${keywords}】の検索結果`}
        totalCount={matchData.length}
        currentPage={1}
      />


      <main>
        {matchData.map( (item,index) => {
          const node = item.node;
          let style = index > 100 ? {display:"none"} : {};

          return(
            <span style={style} key={index}>
                <Card
                  basePath={`/`}
                  slug={node.slug}
                  title={node.title}
                  content={node.childContentfulNetaContentTextNode.content}
                  createdAtJP={node.createdAtJP}
                  publishStartJP={node.publishStartJP}
                  tags={node.tags}
                />
              </span>
          );
        })}


        {matchData.length > 100 && <div style={{textAlign:"center",color:"red",fontWeight:"bold"}}>
          一度に表示できる結果は100件までです。
        </div>}

        {/*<div style={{backgroundColor:"pink"}} onClick={showMore}>*/}
        {/*もっと見る*/}
        {/*</div>*/}


        {!loading && !matchData.length &&<>
        <h4 style={{marginTop:50,textAlign:"center"}}>
          検索結果が見つかりませんでした。
        </h4>
        <div style={{marginTop:50,textAlign:"center"}}>
          <Link to={`/`}>戻る</Link>
        </div>
        </>}

      </main>



    </Layout>
  )
  }
