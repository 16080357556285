// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-privacy-js": () => import("./../../../src/pages/app-privacy.js" /* webpackChunkName: "component---src-pages-app-privacy-js" */),
  "component---src-pages-bookmarks-js": () => import("./../../../src/pages/bookmarks.js" /* webpackChunkName: "component---src-pages-bookmarks-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-how-to-js": () => import("./../../../src/pages/how-to.js" /* webpackChunkName: "component---src-pages-how-to-js" */),
  "component---src-pages-keywords-js": () => import("./../../../src/pages/keywords.js" /* webpackChunkName: "component---src-pages-keywords-js" */),
  "component---src-pages-ranking-js": () => import("./../../../src/pages/ranking.js" /* webpackChunkName: "component---src-pages-ranking-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-blogpost-template-js": () => import("./../../../src/templates/blogpost-template.js" /* webpackChunkName: "component---src-templates-blogpost-template-js" */),
  "component---src-templates-keyword-template-js": () => import("./../../../src/templates/keyword-template.js" /* webpackChunkName: "component---src-templates-keyword-template-js" */),
  "component---src-templates-list-template-js": () => import("./../../../src/templates/list-template.js" /* webpackChunkName: "component---src-templates-list-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

