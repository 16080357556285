import React, { useEffect } from 'react';


export const AdsenseInFeed = (props ) => {
  const { currentPath } = props

  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [currentPath]);

  return (
    <article className="content-card relative">
      <div className="absolute" style={{
        top:-17,left:15,
        backgroundColor:"#551A8B",
        color:"white",
        width:34,
        height:34,
        fontSize:12,
        textAlign:"center",
        lineHeight:"34px",
        borderRadius:"50%",
        fontWeight:"bold"
      }}>
        広告
      </div>
      <ins className="adsbygoogle"
           style={{display: 'block'}}
           data-ad-format="fluid"
           data-ad-layout-key="-e8+6z-35-j0+1a2"
           data-ad-client="ca-pub-2752198174177110"
           data-ad-slot="2679919878">
      </ins>
    </article>
  )
}

export const AdsenseLinkUnit = (props ) => {
  const { currentPath } = props

  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [currentPath]);

  return (
    <ins className="adsbygoogle"
         style={{display: 'block'}}
         data-ad-client="ca-pub-2752198174177110"
         data-ad-slot="2382043571"
         data-ad-format="link"
         data-full-width-responsive="true">
    </ins>
  )
}

export const AdsenseRelated = (props ) => {
  const { currentPath } = props

  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [currentPath]);

  return (
    <ins className="adsbygoogle"
         style={{display: 'block'}}
         data-ad-format="autorelaxed"
         data-ad-client="ca-pub-2752198174177110"
         data-ad-slot="6677392210">
    </ins>
  )
}

export const AdsenseItemBottom = (props ) => {
  const { currentPath } = props

  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [currentPath]);

  return (
    <ins className="adsbygoogle"
         style={{display: 'block'}}
         data-ad-client="ca-pub-2752198174177110"
         data-ad-slot="3299074360"
         data-ad-format="auto"
         data-full-width-responsive="true">
    </ins>
  )
}



export const AdsenseItemTop = (props ) => {
  const { currentPath } = props

  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [currentPath]);

  return (
    <ins className="adsbygoogle"
         style={{display: 'block'}}
         data-ad-client="ca-pub-2752198174177110"
         data-ad-slot="9856714075"
         data-ad-format="horizontal"
         data-full-width-responsive="false">
    </ins>
  )
}




