import React, { useState } from "react";
import Layout from "../components/Layout"
import SEO from "../components/Seo"



export default ({location}) => {

  const [name, setName] = useState("匿名希望");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");


  const styles = {
    title : {
      fontSize:"16px",
      fontWeight:"bold"
    },
    input : {
      height:"30px",
      width:"300px"
    },
    submit : {
      width:100,
      height:40,
    }
  }

  const handleChange = event => {
    const targetName = event.target.name;

    if(targetName === "name")  setName(event.target.value);
    if(targetName === "email")  setEmail(event.target.value);
    if(targetName === "subject")  setSubject(event.target.value);
    if(targetName === "message")  setMessage(event.target.value);
  };



  const Required = () => {
    return (
      <span style={{
        color: "red",
        fontSize:"smaller",
      }}>
        ※
      </span>
    );
  }




  return (
    <Layout>
      <SEO
        title="お問い合わせ"
        pathname={location.pathname}
      />

      <main className="container">

        <section className="column">
          <h1 style={{marginBottom:0,fontSize:20,width:'100%',textAlign:"center"}}>
            お問い合わせ
          </h1>
          <p>
            ご依頼・お問い合わせなどございましたら、下記よりお気軽にご連絡ください。
          </p>
          <form name="contact" method="POST" data-netlify="true" style={{padding:20}}>
            <p>
              <input type="hidden" name="form-name" value="contact" />
              <div style={styles.title}>名前<Required/>:</div>
              <input style={styles.input} required type="text" name="name" value={name} onChange={handleChange}/>
            </p>
            <p>
              <div style={styles.title}>メールアドレス: </div>
              <input style={styles.input} type="email" name="email" value={email} onChange={handleChange}/>
            </p>
            <p>
              <div style={styles.title}>件名<Required/>: </div>
              <input style={styles.input} required type="text" name="subject" value={subject} onChange={handleChange} />
            </p>
            <p>
              <div style={styles.title}>メッセージ<Required/>:</div>
              <textarea required rows={10} cols={45} name="message" value={message} onChange={handleChange}> </textarea>
            </p>
            <p>
              <button style={styles.submit} type="submit">送信する</button>
            </p>
          </form>
        </section>




      </main>



    </Layout>
  )
}
