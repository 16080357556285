import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import {Link} from "gatsby"

//他にもベタがきあり
const keywords = [
  '10月',
  '11月',
  '12月',
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  'AI',
  'IT',
  'あいさつ',
  'あたりまえ',
  'アドバイス',
  'アニメ',
  'アプリ',
  'ありがとう',
  'アルバイト',
  'イチロー',
  'インフルエンザ',
  'ウォーキング',
  'エアコン',
  'エイプリルフール',
  'エピソード',
  'おもてなし',
  'オリンピック',
  'お花見',
  'お客様',
  'お金',
  'お酒',
  'お笑い',
  'お彼岸',
  'お盆',
  'キャンプ',
  'クリスマス',
  'クレーム',
  'ゲーム',
  'コーヒー',
  'ゴールデンウィーク',
  'ことわざ',
  'ゴミ',
  'コミュニケーション',
  'ゴルフ',
  'コロナ',
  'コンビニ',
  'コンプライアンス',
  'サービス',
  'サッカー',
  'ストレス',
  'ストレッチ',
  'スピーチ',
  'スポーツ',
  'スマホ',
  'ダイエット',
  'タバコ',
  'チーム',
  'チームワーク',
  'チャレンジ',
  'チャンス',
  'ディズニー',
  'テーマ',
  'テクニック',
  'テクノロジー',
  'デザイン',
  'テニス',
  'テレワーク',
  'トイレ掃除',
  'ドラマ',
  'ニュース',
  'バイク',
  'ハインリッヒ',
  'バスケ',
  'パソコン',
  'バレンタインデー',
  'ハロウィン',
  'ビール',
  'ビジネス',
  'ビジネスマナー',
  'ひな祭り',
  'ヒヤリハット',
  'ピンチ',
  'ブランド',
  'プロ',
  'プロフェッショナル',
  'ペット',
  'ポイント',
  'ポジティブ',
  'ボランティア',
  'ホワイトデー',
  'マーケティング',
  'マスク',
  'マナー',
  'マラソン',
  'ミス',
  'メモ',
  'メリット',
  'メンタル',
  'モチベーション',
  'やりたくない',
  'やる気',
  'ラーメン',
  'ラジオ',
  'ラジオ体操',
  'ランニング',
  'リフレッシュ',
  'ルール',
  'レンガ',
  'ワークライフバランス',
  'ワード',
  'ワールドカップ',
  '挨拶',
  '安全',
  '安全運転',
  '意識',
  '意味',
  '異動',
  '医療',
  '一期一会',
  '引越し',
  '飲み会',
  '飲酒',
  '飲食店',
  '雨',
  '雨の日',
  '丑の日',
  '嘘',
  '運送',
  '運転',
  '運動',
  '運動会',
  '営業',
  '映画',
  '英語',
  '衛生',
  '駅伝',
  '温泉',
  '音楽',
  '何の日',
  '夏',
  '夏バテ',
  '夏休み',
  '家族',
  '火災',
  '花火',
  '花見',
  '花粉',
  '花粉症',
  '介護',
  '会社',
  '改善',
  '海外',
  '格言',
  '確認',
  '学ぶ',
  '学校',
  '学生時代',
  '乾燥',
  '寒さ',
  '感謝',
  '感動',
  '慣れ',
  '漢字',
  '環境',
  '簡単',
  '危険予知',
  '基本',
  '気づき',
  '気遣い',
  '季節',
  '記憶',
  '記憶力',
  '技術',
  '休み',
  '休日',
  '競馬',
  '教える',
  '教育',
  '業務',
  '勤労感謝',
  '禁煙',
  '筋トレ',
  '筋肉',
  '緊張',
  '苦手',
  '靴',
  '経験',
  '経済',
  '継続',
  '継続は力なり',
  '計画',
  '警察',
  '結婚',
  '結婚式',
  '月末',
  '月曜日',
  '健康',
  '健康管理',
  '健康診断',
  '建設',
  '犬',
  '研修',
  '元気',
  '現場',
  '後輩',
  '交通',
  '交通安全',
  '交通事故',
  '効果',
  '効率',
  '効率化',
  '好きなこと',
  '工場',
  '工夫',
  '幸せ',
  '甲子園',
  '紅葉',
  '行動',
  '高校生',
  '高校野球',
  '高齢者',
  '腰痛',
  '座右の銘',
  '災害',
  '祭り',
  '桜',
  '散歩',
  '残業',
  '仕事',
  '四字熟語',
  '子ども',
  '子育て',
  '子供',
  '師走',
  '思いやり',
  '思い込み',
  '思い出',
  '指導',
  '資格',
  '歯',
  '事故',
  '事務',
  '時間',
  '時間管理',
  '自己啓発',
  '自信',
  '自転車',
  '自動車',
  '自由',
  '七五三',
  '七夕',
  '失敗',
  '車',
  '手紙',
  '手帳',
  '趣味',
  '酒',
  '受験',
  '秋',
  '習慣',
  '出会い',
  '春',
  '春分の日',
  '準備',
  '初詣',
  '初心',
  '暑さ',
  '女性',
  '焦る',
  '笑い',
  '笑う',
  '笑顔',
  '常識',
  '情報',
  '食べ物',
  '食事',
  '信用',
  '信頼',
  '心',
  '心理',
  '振り返り',
  '新人',
  '新入社員',
  '新年',
  '新年度',
  '深呼吸',
  '親',
  '身だしなみ',
  '震災',
  '人',
  '人間関係',
  '人生',
  '図書館',
  '睡眠',
  '数字',
  '成人',
  '成人式',
  '成長',
  '整理',
  '整理整頓',
  '正月',
  '清掃',
  '生活',
  '製造業',
  '責任',
  '赤ちゃん',
  '接客',
  '接遇',
  '節分',
  '雪',
  '占い',
  '選挙',
  '前向き',
  '組織',
  '掃除',
  '相撲',
  '総務',
  '卒業',
  '卒業式',
  '尊敬する人',
  '体験',
  '体操',
  '体調',
  '体調管理',
  '退職',
  '台風',
  '大切なもの',
  '第一印象',
  '卓球',
  '短い',
  '誕生日',
  '断捨離',
  '段取り',
  '地域',
  '地震',
  '中学生',
  '中国',
  '中秋の名月',
  '虫',
  '挑戦',
  '朝',
  '朝食',
  '朝礼',
  '通勤',
  '釣り',
  '天気',
  '転職',
  '伝える',
  '電車',
  '電話',
  '努力',
  '土木',
  '土用の丑',
  '冬',
  '冬至',
  '投資',
  '東日本大震災',
  '当たり前',
  '豆知識',
  '働き方',
  '働き方改革',
  '動物',
  '特徴',
  '読書',
  '内容',
  '日常',
  '入試',
  '入社',
  '猫',
  '熱中症',
  '年の瀬',
  '年賀状',
  '年始',
  '年末',
  '年末年始',
  '梅雨',
  '箱根駅伝',
  '反省',
  '彼岸',
  '疲労',
  '美容',
  '必要性',
  '評価',
  '病院',
  '病気',
  '品質',
  '不安',
  '不動産',
  '富士山',
  '父の日',
  '部下',
  '風邪',
  '文化',
  '文章',
  '壁',
  '別れ',
  '変化',
  '勉強',
  '保育園',
  '保健',
  '保険',
  '保険会社',
  '報告',
  '報連相',
  '宝くじ',
  '抱負',
  '法則',
  '褒める',
  '忘年会',
  '忙しい',
  '防災',
  '防犯',
  '本',
  '漫画',
  '夢',
  '無駄',
  '名言',
  '面白い',
  '目',
  '目的',
  '目標',
  '野球',
  '野菜',
  '役に立つ',
  '約束',
  '優先順位',
  '友人',
  '予防接種',
  '幼稚園',
  '利益',
  '陸上',
  '旅行',
  '両親',
  '料理',
  '令和',
  '礼儀',
  '歴史',
  '連休',
  '話し方',
  '話題',

];



export default ({location}) => {

  const Keyword = ({keyword}) => (
    <Link to={`/keyword/${keyword}/`}>
      <div style={{
        backgroundColor:"#ccc",
        margin:7,
        display:"inline-block",
        padding:"5px 10px"
      }}>
        {keyword}
      </div>
    </Link>
  );


  return (
    <Layout>
      <SEO
        title="キーワード一覧"
        pathname={location.pathname}
      />

      <main className="container">

        <section className="column">
          <h1 style={{marginBottom:0,fontSize:20,width:'100%',textAlign:"center"}}>
            キーワード一覧
          </h1>

        </section>

        <section className="column">
          {keywords.map((item,index)=> <Keyword keyword={item} key={index}/>)}
        </section>

      </main>



    </Layout>
  )
}
