import React from "react"
import InfoCard from "./InfoCard";

export default () => {
  return(
    <InfoCard>
      <h3>
        朝礼ネタ アプリ版 <span style={{color:"red"}}>[New]</span>
      </h3>

      <div style={{display:"flex"}}>
        <div>
          <img width="60" src="/images/appicon.png"/>
        </div>
        <div style={{marginLeft:10}}>
          Android、iOSに対応したスマホアプリをリリースしました。
        </div>
      </div>
      <div style={{display:"flex"}}>
        <div>
          <a href="https://play.google.com/store/apps/details?id=jp.speech_3min">
            <img width="140" alt="朝礼ネタ Androidアプリ" src="/images/btn-googleplay.png"/>
          </a>
        </div>
        <div>
          <a href="https://apps.apple.com/jp/app/id1550216211">
            <img width="140" alt="朝礼ネタ iOSアプリ" src="/images/btn-appstore.png"/>
          </a>
        </div>
      </div>


      <hr/>
      <h3 style={{marginTop:40}}>
        <a target="_blank" rel="noreferrer" href="https://www.amazon.co.jp/gp/product/B07F8N2YGZ/ref=as_li_tl?ie=UTF8&amp;camp=247&amp;creative=1211&amp;creativeASIN=B07F8N2YGZ&amp;linkCode=as2&amp;tag=imayounaoto-22&amp;linkId=3f3cd84e5e48ba24e88334a9c7eb2c9f">
        朝礼ネタ 電子書籍のご紹介
        </a>
      </h3>
      <div style={{display:"flex"}}>
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.amazon.co.jp/gp/product/B07F8N2YGZ/ref=as_li_tl?ie=UTF8&amp;camp=247&amp;creative=1211&amp;creativeASIN=B07F8N2YGZ&amp;linkCode=as2&amp;tag=imayounaoto-22&amp;linkId=3f3cd84e5e48ba24e88334a9c7eb2c9f"><img alt="朝礼ネタ" border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&amp;MarketPlace=JP&amp;ASIN=B07F8N2YGZ&amp;ServiceVersion=20070822&amp;ID=AsinImage&amp;WS=1&amp;Format=_SL110_&amp;tag=imayounaoto-22"/>
            </a>
          </div>
          <div style={{marginLeft:20}}>
            <iframe
              src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=21&l=ur1&category=kindleunlimited&banner=178KN9KSS2XDCENS02G2&f=ifr&linkID=54f1be535eb857b491e614802d820003&t=imayounaoto-22&tracking_id=imayounaoto-22"
              width="125" height="125" scrolling="no" border="0" marginWidth="0" style={{border:"none"}}
              frameBorder="0"/>
          </div>
      </div>
      <div>
        掲載中の例文から人気ネタを集めました。
        厳選ネタをじっくりと読みたい方にオススメです。<br/>
        スマホでもkindleアプリで読めますので是非ともご覧ください！<br/>
        <span style={{fontSize:"smaller"}}>
            <a href="https://www.amazon.co.jp/kindle-dbs/hz/signup?tag=imayounaoto-22">
            ★Unlimitedユーザーは全巻読み放題
            </a>
          </span>
      </div>


    </InfoCard>
  )
}