import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import {graphql} from "gatsby"
import {Link} from "gatsby"
import SubTitle from "../components/SubTitle"
import {getNowYMDhmsStr} from "../functions"
const now = getNowYMDhmsStr();

export const query = graphql`
query  {
  allContentfulNeta(limit: 10000,
    sort: {fields: slug, order: DESC}
  ) {
    edges {
      node {
        slug
        id
        title
        createdAt
        createdAtJP:createdAt(formatString:"YYYY/MM/DD")
        publishStartJP:publishStart(formatString:"YYYY/MM/DD")
        publishStart
        tags {
          id
          title
          slug
        }
      }
    }
  }
}
`;



export default ({data,location}) => {

  // let allPageViews = {};
  // data.allPageViews.edges.forEach(({node})=>{
  //   allPageViews[node.id.replace(/[^0-9]/g, '')] = node.totalCount;
  // });


  return (
    <Layout>
      <SEO
        title="サイトマップ"
        pathname={location.pathname}
      />

      <main className="container">

        <section className="column">
          <SubTitle>サイトマップ</SubTitle>
          <div className="info">{data.allContentfulNeta.edges.length} 件 の朝礼ネタを表示します。</div>
        </section>

        <section className="column">
          {data.allContentfulNeta.edges.map( (item,index) => {
            const node = item.node;
            const published = node.publishStart < now;
            const showFuture = location.search.match(/all/);



            return(
              published ?
              <ul key={index}>
                <li>
                <Link to={`/neta/${node.slug}/`}>
                  {node.title}
                </Link>
                </li>
                <span style={{fontSize:10,color:"gray"}}> (No.{node.slug}) {node.publishStartJP}
                {/*- {allPageViews[node.slug]}pv*/}
                </span>
              </ul>
                :
                showFuture ?
                <ul key={index}>
                  <li>
                    <span>
                       <span style={{fontSize:11,color:"gray"}}>{node.title} (No.{node.slug}) {node.publishStartJP} 公開予定</span>
                    </span>
                  </li>
                </ul> : ""

            );
          })}
        </section>
      </main>




    </Layout>
  )
}
