import React from "react"
import "./Loading.css"


export default ({show,message="Loading..."}) => {
  return(
    show ?
      <div id="fullOverlay">
        <div className="text">
          <div
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
        </div>
      </div> : ""
  )
}