import React from "react"


export default ({children,title="お知らせ"}) => {

  return(
    <article className="content-card relative"  style={{background:""}}>
      <div className="absolute" style={{
        top:-17,left:15,
        backgroundColor:"#551A8B",
        color:"white",
        width:68,
        height:34,
        fontSize:12,
        textAlign:"center",
        lineHeight:"34px",
        borderRadius:"50%",
        fontWeight:"bold"
      }}>
        {title}
      </div>

      <div>
      {children}
      </div>
    </article>
  )



}
