import React,{useEffect} from "react"
import {Helmet} from "react-helmet"
import {useStaticQuery,graphql} from "gatsby"

import { withPrefix } from "gatsby";

export default props => {

  const appendScript = (id,src) =>{
    const elm = document.createElement("script");
    elm.id = id;
    if(id==='googleAds') {
      elm.dataset.adClient = "ca-pub-2752198174177110"
    }
    elm.async = true;
    elm.src = src;
    document.body.appendChild(elm)
  }
  const removeScript = (id) => {
    const elm = document.getElementById(id);
    document.body.removeChild(elm)
  }

  useEffect(() => {

  //  appendScript("artisan",withPrefix('artisan.js'));
    appendScript("googleAds","//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js");
 //   appendScript("accaii","//accaii.com/imayounaoto401/script.js");

    return () => {
  //    removeScript("artisan");
      removeScript("googleAds");
 //     removeScript("accaii");
    }

  }, [])

  const data = useStaticQuery(graphql`
query {
  site {
    id
    siteMetadata {
      author
      description
      lang
      title
      siteUrl
      locale
      image
      twitterUsername
    }
  }
}
  `);
  const siteMetadata = data.site.siteMetadata;
  //console.log(siteMetadata)

  const title = props.title || siteMetadata.title;
  const description = props.description || siteMetadata.description;
  const url = props.pathname ? `${siteMetadata.siteUrl}${props.pathname}` : siteMetadata.siteUrl;


  return(
    <Helmet>
      <html lang="ja"/>
      <title>{title}</title>
      <meta name="description" content={description}/>

      <link rel="canonical" href={siteMetadata.siteUrl}/>

      <meta property="og:site_name" content={siteMetadata.title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:image" content={`${siteMetadata.siteUrl}${siteMetadata.image}`} />


      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={siteMetadata.twitterUsername} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteMetadata.siteUrl}${siteMetadata.image}`}  />
      {/*<script src="//accaii.com/imayounaoto401/script.js" async />*/}
      {/* GoogleアドセンスのJSを読み込む */}
      {/*<script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"/>*/}


    </Helmet>
  )
}