import React from "react"
import {Link} from "gatsby"

export default () => {
  return(
    <footer>
      <div className="footer-block">
        <Link to={`/`}>
        <h2 className="footer-title footer-h2"><img className="header-img footer-img" src="/images/fukurou_150x150.jpg" alt="朝礼ネタ"/>
        <span className="footer-span">
          朝礼ネタ　3分間スピーチの例文集
        </span>
        </h2>
        </Link>
        <p className="footer-p">
          朝礼ネタに困っている人の強い味方！<br/>
          国内最大級のスピーチ例文を保有する、検索サービスです。
        </p>

        <div style={{
          color:"white",
          display:"flex",
          flexDirection:"row",
          justifyContent:"center",
          fontSize:12,
        }}>
          <div>
            <ul style={{listStyle:"none"}}>
              <li><Link to={`/`}>・トップ</Link></li>
              <li><Link to={`/category/`}>・カテゴリー</Link></li>
              <li><Link to={`/about/`}>・サイトについて</Link></li>
              <li><Link to={`/bookmarks/`}>・お気に入り</Link></li>
            </ul>
          </div>
          <div>
            <ul style={{listStyle:"none"}}>
              <li><Link to={`/ranking/`}>・ランキング</Link></li>
              <li><Link to={`/how-to/`}>・スピーチのコツ</Link></li>
              <li><Link to={`/sitemap/`}>・全記事一覧</Link></li>
              {/*<li>・プライバシーポリシー</li>*/}
              <li><Link to={`/contact/`}>・お問い合わせ</Link></li>
            </ul>
          </div>
        </div>
      </div>

      <p className="copyright">
        <Link to={`/`}>
          {new Date().getFullYear()} © 朝礼ネタ 3min-speech.jp
        </Link>
      </p>
    </footer>
  );

}