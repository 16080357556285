import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'


export default ({children}) => {
  return(
    <>
     <FontAwesomeIcon icon={faCheckSquare} style={{display:"inline-block",color:"blue"}}/>
      <h2 style={{display:"inline-block", marginLeft:10}}>
        {children}
      </h2>
      <br/>
    </>
  )
}
