module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"朝礼ネタ 3分間スピーチの例文集","short_name":"朝礼ネタ","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/fukurou_400x400.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"38f2740441333d4234e30cebe233e61a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
