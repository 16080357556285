import React from "react"
import Layout from "../components/Layout"
import {graphql} from "gatsby"
import SEO from "../components/Seo"
import SearchResultHeader from "../components/SearchResultHeader"
import Card from "../components/Card"
import Pager from "../components/Pager"
import InfoCard1 from "../components/InfoCard1";
import {AdsenseInFeed} from "../components/Adsense"

export const query = graphql`
query ($id: String!, $skip:Int!, $limit: Int!,$now: Date!) {
  allContentfulNeta(
    sort: {fields: slug, order: DESC}
  	limit:$limit
    skip:$skip
    filter: {
      publishStart:{lt:$now},
      tags: {elemMatch: {id: {eq: $id}}}
    }
  ) {
    edges {
      node {
        title
        id
        slug
        createdAt
        createdAtJP:createdAt(formatString:"YYYY/MM/DD")
        publishStartJP:publishStart(formatString:"YYYY/MM/DD")
        tags {
          id
          title
          slug
        }
        childContentfulNetaContentTextNode {
          content
        }
      }
    }
    totalCount
  }
  
}
`;


export default ({data, location, pageContext}) => {

  const {allContentfulNeta} = data;

  // const basePath = pageContext.currentPage === 1 ? `../../` : `../../../../`;
  const str = pageContext.order.match(/CC/) ? '職種の朝礼ネタ' : 'の朝礼ネタ';
  return(
    <Layout>
      <SEO
        title={`【${pageContext.title}】の朝礼ネタ | 3分間スピーチの例文集`}
        description={`${pageContext.title} ${str}`}
        pagepath={location.pathname}
      />


      <SearchResultHeader
        text={`【${pageContext.title}】 ${str}`}
        totalCount={pageContext.totalCount}
        currentPage={pageContext.currentPage}
      />

      <article id="sec02">
        <div className="innerS">
          {allContentfulNeta.edges.map( ({node},index) => (
            <span key={index}>
            <Card
              basePath={`/`}
              slug={node.slug}
              title={node.title}
              content={node.childContentfulNetaContentTextNode.content}
              createdAtJP={node.createdAtJP}
              publishStartJP={node.publishStartJP}
              tags={node.tags}
              // pageView={node.slug in pageContext.allPageViews ? pageContext.allPageViews[node.slug] : 1 }
            />
              {[1,7,19].includes(index) && <AdsenseInFeed/>}
            </span>
          ))}

          <InfoCard1/>

          <Pager
            isFirst={pageContext.isFirst}
            isLast={pageContext.isLast}
            totalPages={pageContext.totalPages}
            currentPage={pageContext.currentPage}
            basePath={`/tag/${pageContext.slug}/`}
          />
        </div>
      </article>



    </Layout>
  );
}


