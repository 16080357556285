import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import {graphql} from "gatsby"
import Card from "../components/Card"
import {Link} from "gatsby"
import SearchResultHeader from "../components/SearchResultHeader"


export const query = graphql`
query ($now: Date!,$keyword: String!) {
  allContentfulNeta(limit: 10000,
       filter:{
       publishStart:{lt:$now}
        title:{regex:$keyword}
       }
  ) {
    edges {
      node {
        slug
        id
        title
        createdAt
        createdAtJP:createdAt(formatString:"YYYY/MM/DD")
        publishStartJP:publishStart(formatString:"YYYY/MM/DD")
        publishStart
        tags {
          id
          title
          slug
        }
        childContentfulNetaContentTextNode {
          content
        }
      }
    }
  }
  
  allContentfulNeta2:allContentfulNeta(limit: 10000,
       filter:{
       publishStart:{lt:$now}
        childContentfulNetaContentTextNode:{
          content:{regex:$keyword}
        }
       }
  ) {
    edges {
      node {
        slug
        id
        title
        createdAt
        createdAtJP:createdAt(formatString:"YYYY/MM/DD")
        publishStartJP:publishStart(formatString:"YYYY/MM/DD")
        publishStart
        tags {
          id
          title
          slug
        }
        childContentfulNetaContentTextNode {
          content
        }
      }
    }
  }  
  
}
`;

console.log(query)

export default ({data, location, pageContext}) => {


  const keyword = pageContext.keyword.replace('/', '').replace('/', '');


  let _matchData1 = data.allContentfulNeta.edges.filter(
    ({node}) => node.title.indexOf(keyword) !== -1
  );
  let slugs = [];
  _matchData1.map(item => slugs.push(item.node.slug));


  let _matchData2 = data.allContentfulNeta2.edges.filter(({node}) => {

    const text = node.childContentfulNetaContentTextNode.content;
    let match = text.indexOf(keyword) !== -1;
    match &= slugs.indexOf(node.slug) === -1;

    return match;
  });

  const matchData = _matchData1.concat(_matchData2);



  const title = `朝礼ネタ - ${keyword} のスピーチ例文一覧`;


  return (
    <Layout>


      <SEO
        title={title}
        pathname={location.pathname}
      />

      <SearchResultHeader
        text={`”${keyword}” の朝礼ネタ`}
        totalCount={matchData.length}
        currentPage={1}
      />


      <main>
        {matchData.map((item, index) => {
          const node = item.node;
          let style = index > 100 ? {display: "none"} : {};

          return (
            <span style={style} key={index}>
                <Card
                  basePath={`/`}
                  slug={node.slug}
                  title={node.title}
                  content={node.childContentfulNetaContentTextNode.content}
                  createdAtJP={node.createdAtJP}
                  publishStartJP={node.publishStartJP}
                  tags={node.tags}
                />
              </span>
          );
        })}


        {matchData.length > 100 && <div style={{textAlign: "center", color: "red", fontWeight: "bold"}}>
          一度に表示できる結果は100件までです。
        </div>}


        {!matchData.length && <>
          <h4 style={{marginTop: 50, textAlign: "center"}}>
            検索結果が見つかりませんでした。
          </h4>
          <div style={{marginTop: 50, textAlign: "center"}}>
            <Link to={`/`}>戻る</Link>
          </div>
        </>}

      </main>


    </Layout>
  )
}
