import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import "./Layout.css"

import "@fortawesome/fontawesome-svg-core/styles.css"
import {config} from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false;

export default ({children}) => {

  return(
    <div>
      <Header/>
        <div className="mbSpacer"></div>
        {children}
      <Footer/>
    </div>
  )
}