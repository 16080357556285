import React,{useEffect,useState} from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import {graphql,Link} from "gatsby"

import SubTitle from "../components/SubTitle"


export const query = graphql`
query  {
  allContentfulTag (
      sort: {fields: order, order: ASC}
      filter:{order:{regex:"/^AA/"} }
  ){
    edges {
      node {
        title
        slug
        order
      }
    }
  }
  allContentfulNeta(limit: 10000,
    sort: {fields: slug, order: ASC}
  ) {
    edges {
      node {
        slug
        id
        title
        createdAt
        createdAtJP:createdAt(formatString:"YYYY/MM/DD")
        publishStartJP:publishStart(formatString:"YYYY/MM/DD")
        publishStart
        tags {
          id
          title
          slug
        }
      }
    }
  }
}
`;



export default ({data,location}) => {

  const [selected,setSelected] = useState('all');
  const [rankingList, setRankingList] = useState([]);


  useEffect(() => {
    const allNeta = getAllNeta();

    setRankingList(allNeta);
  },[])

  const getAllNeta = () => {
    // let allPageViews = {};
    // data.allPageViews.edges.forEach(({node})=>{
    //   allPageViews[node.id.replace(/[^0-9]/g, '')] = node.totalCount;
    // });

    let allNeta = [];
    data.allContentfulNeta.edges.map( (item,index) => {
      // item.node.totalCount = allPageViews[item.node.slug];
      allNeta[item.node.slug] = item.node;
    })
    allNeta.sort((a,b)=> {
      return b.totalCount - a.totalCount;
    })
    return allNeta;
  }

  const handleChange = (e) => {
    const selected = e.target.value;

    let allNeta = getAllNeta();

    if(selected==='all'){
      setRankingList(getAllNeta());
    }else {
      let tmp = [];
      allNeta.map(item => {
        if (item.tags) {
          item.tags.map(tag => {
            if (tag.slug === selected) {
              tmp.push(item);
            }
          })
        }
      });
      tmp.sort((a,b)=> {
        return b.totalCount - a.totalCount;
      })
      setRankingList(tmp);
    }

    setSelected(selected)
  }

  let tags = [];
  tags.push({
    title : 'すべてのカテゴリー',
    slug : 'all',
    order : 'AA000',
  })
  data.allContentfulTag.edges.map(item=>{
    tags.push(item.node)
  });




  return (
    <Layout>
      <SEO
        title="カテゴリー別ランキング"
        pathname={location.pathname}
      />

      <main className="container">

        <section className="column">
          <SubTitle>カテゴリー別ランキング</SubTitle>

          {/*<span>表示したいカテゴリーを選択してください。</span>*/}
          <select
            onChange={handleChange}
            value={selected}
            className="search-category"
          >
            {tags.map(tag=>{
              return <option value={tag.slug}>{tag.title}</option>
            })}
          </select>

          {rankingList.slice(0,20).map((item,index)=>{
            return(
              <div style={{marginTop:20}}>
                <span style={{fontWeight:"bold"}}>
                  {index+1}.　
                </span>
                <Link to={`/neta/${item.slug}/`}>
                  {item.title}
                </Link>
                {/*<span style={{fontSize:10,color:"gray"}}>　{item.totalCount}pv</span>*/}
              </div>
            )
          })}
        </section>


      </main>




    </Layout>
  )
}
