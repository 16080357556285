import React, { useState } from 'react';
import {Link} from "gatsby"
import ModalSearch from "./ModalSearch";

export default () => {

  const [isOpen, setOpen] = useState(false);

  const closeClass = isOpen ? 'close' : '';

  const panelStyle = isOpen ? {
    display:"block"
  }: {};

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return(
    <header>
      <div style={{
        display:"inline-block",
        position: "fixed",
        top: 10,
        left: 10,
        zIndex:9999
      }}>
        <ModalSearch/>
      </div>
      <Link to={`/`}>
      <h1 className="title"><img className="header-img" src="/images/fukurou_150x150.jpg" alt="朝礼ネタ" />
        <span className="header-span">朝礼ネタ　3分間スピーチの例文集</span>
      </h1>
      </Link>
      {/* ハンバーガーメニュー */}　
      <div className="hamburger-menu">
        <input type="checkbox" id="menu-btn-check" />
        <label htmlFor="menu-btn-check" className="menu-btn"><span /></label>
        <div className="menu-content">
          <ul>
            <li><Link to={`/`}>トップ</Link></li>
            <li><Link to={`/category/`}>カテゴリー</Link></li>
            <li><Link to={`/ranking/`}>ランキング</Link></li>
            {/*<li><Link to={`/about/`}>サイトについて</Link></li>*/}
            <li><Link to={`/sitemap/`}>全記事一覧</Link></li>
            <li><Link to={`/bookmarks/`}>お気に入り</Link></li>
            <li><Link to={`/how-to/`}>スピーチのコツ</Link></li>
          </ul>
        </div>
      </div>
      {/* ナビバー */}
      <nav className="menu">
        <Link to={`/`}>トップ</Link>
        <Link to={`/category/`}>カテゴリー</Link>
        <Link to={`/ranking/`}>ランキング</Link>
        {/*<Link to={`/about/`}>サイトについて</Link>*/}
        <Link to={`/sitemap/`}>全記事一覧</Link>
        <Link to={`/bookmarks/`}>お気に入り</Link>
        <Link to={`/how-to/`}>スピーチのコツ</Link>
      </nav>
    </header>
  )

}