import React,{useState,useEffect} from "react"
import Layout from "../components/Layout"
import {graphql} from "gatsby"
import Search from "../components/Search"
import SearchResultHeader from "../components/SearchResultHeader"
import Card from "../components/Card"
import Pager from "../components/Pager"
import SEO from "../components/Seo"
import Axios from "axios"
import InfoCard1 from "../components/InfoCard1";
import {AdsenseInFeed} from "../components/Adsense"
import TopContents from "../components/TopContents";

export const query = graphql`
query ($skip:Int!, $limit: Int!,$now: Date!) {
  allContentfulNeta(
    sort: {fields: publishStart, order: DESC}
     filter:{publishStart:{lt:$now}}
  	limit:$limit
    skip:$skip
  ) {
    edges {
      node {
        title
        id
        slug
        createdAt
        createdAtJP:createdAt(formatString:"YYYY-MM-DD")
        publishStartJP:publishStart(formatString:"YYYY/MM/DD")
        tags {
          id
          title
          slug
        }
        childContentfulNetaContentTextNode {
          content
        }
      }
    }
    totalCount
  }
}
`;

export default ({data, location, pageContext}) => {

  const {allContentfulNeta} = data;
  const [edges, setEdges] =useState([]);


  function getNowYMD(){
    var dt = new Date();
    var y = dt.getFullYear();
    var m = ("00" + (dt.getMonth()+1)).slice(-2);
    var d = ("00" + dt.getDate()).slice(-2);
    var result = y + m + d;
    return result;
  }

  useEffect(() => {

    // 表示期間　超えたら出さない
    if(getNowYMD() > 20211208){
      return;
    }

    const topIds = [4026,4023];
    Axios.get("/page-data/search/page-data.json")
      .then(result => {
        let topContent = [];
        const edges = result.data.result.data.allContentfulNeta.edges;
        edges.map((edge)=>{
          if(topIds.indexOf(edge.node.slug)!==-1){
            topContent.push(edge);
            if(topIds.length===topContent.length){
              return;
            }
          }
        });
        setEdges(topContent);
      });
  }, []);


  //一旦先読みしてキャッシュに載せておく
  if(pageContext.currentPage === 1) {
    setTimeout(()=>{
      Axios.get("/page-data/search/page-data.json")
        .then(result => {
       //   console.log(result.data.result.data.allContentfulNeta.edges);
        });
    },2000)
  }

  // const basePath = pageContext.currentPage === 1 ? `../` : `../../`;

  return(
    <Layout>
      <SEO/>

      {pageContext.currentPage === 1 && <Search/>}


      <SearchResultHeader
        text="全ての朝礼ネタを表示中"
        totalCount={pageContext.totalCount}
        currentPage={pageContext.currentPage}
      />



      <main>
        {pageContext.currentPage === 1 && <TopContents edges={edges}/>}


          {allContentfulNeta.edges.map( ({node},index) => (

            <>
            <Card
              key={index}
              basePath={`/`}
              slug={node.slug}
              title={node.title}
              content={node.childContentfulNetaContentTextNode.content}
              createdAtJP={node.createdAtJP}
              publishStartJP={node.publishStartJP}
              tags={node.tags}
              // pageView={node.slug in pageContext.allPageViews ? pageContext.allPageViews[node.slug] : 0}
            />
              {[1,7,19].includes(index) && <AdsenseInFeed/>}
            </>

          ))}

        <InfoCard1/>
        <AdsenseInFeed/>


          <Pager
            isFirst={pageContext.isFirst}
            isLast={pageContext.isLast}
            totalPages={pageContext.totalPages}
            currentPage={pageContext.currentPage}
            basePath="/"
          />
      </main>



    </Layout>
  );
}