import React,{useState,useEffect} from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import {graphql} from "gatsby"
import {Link} from "gatsby"
import SaveBtn from "../components/SaveBtn"
import SubTitle from "../components/SubTitle"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBeforeBook} from '@fortawesome/free-regular-svg-icons'


export const query = graphql`
query  {
  allContentfulNeta(limit: 10000,

  ) {
    edges {
      node {
        slug
        id
        title
        createdAt
        createdAtJP:createdAt(formatString:"YYYY/MM/DD")
        publishStartJP:publishStart(formatString:"YYYY/MM/DD")
        tags {
          id
          title
          slug
        }
      }
    }
  }
}
`;


export default ({data,location}) => {

  const [books,setBooks] = useState([]);
  const allData = data.allContentfulNeta.edges;

  useEffect(() => {
    let storageBooks =  JSON.parse(localStorage.getItem('books'));
    if(storageBooks===null){
      storageBooks = [];
    }
    setBooks(storageBooks);
  },[]);


  const bookMarks = allData.filter(item=> books.includes(item.node.slug));


  return (
    <Layout>
      <SEO
        title="お気に入りのネタ一覧"
        pathname={location.pathname}
      />

      <main className="container">
        <section className="column">
          <SubTitle>お気に入りのネタ一覧</SubTitle>

          {bookMarks.length===0 &&
          <div>
            <div style={{color:"red",fontWeight:"bold"}}>記事がありません。</div>
            <div style={{marginTop:20}}>
              各ネタに表示されているアイコン<FontAwesomeIcon icon={faBeforeBook} style={{fontSize:30,color:"blue"}}/> をクリックすると、
              このページに表示されます。
              <div style={{marginTop:10,fontSize:"smaller"}}>※データはご利用の端末に保存されますので、ログイン無しで気軽にご利用できます。</div>

            </div>
          </div>}
          {bookMarks.map( (item,index) => {
            const node = item.node;
            return(
              <div key={index}>
                <SaveBtn slug={node.slug}/>　
                <Link to={`/neta/${node.slug}/`}>
                  {node.title}
                </Link>

                <span style={{fontSize:10,color:"gray"}}> (No.{node.slug}) {node.publishStartJP} </span>
              </div>

            );
          })}
        </section>
      </main>







    </Layout>
  )
}
