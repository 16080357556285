import React from "react"
import Card from "./Card";

export default ({edges}) => {

  return(
    <>
      {edges.map(({node},index)=>{
        return(
          <Card
            key={index}
            basePath={`/`}
            slug={node.slug}
            title={node.title}
            content={node.childContentfulNetaContentTextNode.content}
            createdAtJP={node.createdAtJP}
            publishStartJP={node.publishStartJP}
            tags={node.tags}
             // pageView={node.slug in pageContext.allPageViews ? pageContext.allPageViews[node.slug] : 0}
          />
        )
      })}
    </>

  )



}
