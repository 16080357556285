import React from "react";
import Modal from "react-modal";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, connectHits, Configure } from 'react-instantsearch-dom';
import "./ModalSearch.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {Link} from "gatsby"

const Hits = ({ hits }) => {
  return(
    <div className="search-items"
         style={{marginTop: 20}}
    >
      {hits.map(hit => (
        <div className="search-item" key={hit.objectID} style={{borderBottom: "1px dotted gray", padding: 4}}>
          <div className="search-item-title">


            <Link to={`/neta/${hit.slug}/`} style={{textDecoration:"none"}}>
              {hit.title}
            </Link>

          </div>
        </div>
      ))}
    </div>
  );
};
const CustomHits = connectHits(Hits);
const algoliaClient = algoliasearch("8HJ5VGC0CN", "ea93d62d0b92aa6e824bb9ce1bd3090b");

export const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    return algoliaClient.search(requests)
  }
}

Modal.setAppElement("#___gatsby");

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.65)"
  },
  content: {
    position: "absolute",
    top: "80px",
    left: 10,
    right: 10,
    margin: "auto",
    bottom: "50px",
    backgroundColor: "#F9FDF8",
    borderRadius: "1rem",
    padding: "1.5rem",
    maxWidth:"400px"
  },
  button:{
    position: "absolute",
    top:10,
    right:10,
    // fontWeight:"bold",
    fontSize:30,
    color:"blue"
  },
  searchIcon:{
    position: "absolute",
    left:10,
    top:0,
    cursor:"pointer",

    width:60,
    // border:"1px solid red"
  }

};

export default function ModalSearch() {
  const [modalIsOpen, setIsOpen] = React.useState(false);


  return (
    <div className="modalSearch">
      <span style={modalStyle.searchIcon} className="searchIcon"  onClick={() => setIsOpen(true)}>
        <div style={{textAlign:"center"}}>
        <FontAwesomeIcon  color="blue" icon={faSearch} />
        </div>
      </span>
      <Modal isOpen={modalIsOpen} style={modalStyle} onRequestClose={() => setIsOpen(false)}>
        <InstantSearch searchClient={searchClient} indexName="3min-speech">
          <Configure hitsPerPage={30} />
          <div style={{
            fontSize:12,
            color:"#555"
          }}>クイック検索</div>
          <SearchBox defaultRefinement="" />
          <CustomHits/>
        </InstantSearch>
        <a
          style={modalStyle.button}
          onClick={() => setIsOpen(false)}>×
        </a>
      </Modal>
    </div>
  );
}