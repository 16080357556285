import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import SubTitle from "../components/SubTitle"

const styles={
  matome : {
    padding:"5px",
    border:"1px solid lavender",
    backgroundColor:"aliceblue",
    // fontWeight:"bold"
  }
}

export default ({data,location}) => {
  return (
    <Layout>
      <SEO
        title="スピーチのコツとテクニック"
        pathname={location.pathname}
      />



      <main className="container">


        <article className="content-card" style={{width:"90%"}}>
          <a href="javascript:void(0)">
            <h1>
              スピーチのコツとテクニック
            </h1>
          </a>


          <SubTitle>スピーチに必要なこと</SubTitle>
          <p>
            良いスピーチを行うために必要なことって、なんだと思いますか？<br/>「スピーチの内容が一番大切」だ、と思われるでしょう。<br/><br/>

            もちろんスピーチの内容はとても大切です。内容の薄い、そして中身のないスピーチは「時間の無駄」と思われてしまいかねません。きっと、そう思った経験もあるのでは・・・？<br/>

            でも、スピーチの内容と同じほど、場合によっては内容以上に大切になるものがあります。<br/>それは、スピーチの仕方、です。<br/><br/>

            人はよく、「何を話すか」だけでなく、「どのように話すか」にも注目するものです。内容が同じだとしても、スピーチの仕方が上手だったら聞き手の受ける印象は全く異なるものなんです。<br/>

            もっと言えば、スピーチの内容がそれほど濃いものでなくても、きちんとした話し方を習得すれば、人はいい話を聞いた、立派なスピーチだった、という印象が残るということ！！<br/><br/>

            ではどうすればスピーチが上手くなれるのでしょうか？<br/><br/>今回はいくつかポイントを絞って、人を引きつける上手なスピーチをするためのテクニックをご紹介します。
          </p>

          <h3>これから紹介すること</h3>
          <ul>
            <li>言葉をはっきり述べる</li>
            <li>ふさわしい休止も必要</li>
            <li>身振り手振り</li>
            <li>顔の表情</li>
            <li>アイコンタクトを忘れない</li>
            <li>抑揚</li>
            <li>会話的なスピーチ</li>
          </ul>
          <br/>

          <p style={{color:"blue"}}>
            この記事は1万文字以上の長文です。<br/>
            お時間ある時にお付き合いいただければと思います。
          </p>

          <br/><br/>


          <SubTitle>①言葉をはっきり述べる</SubTitle>

          <p>
            まず最初にあげたいのは、言葉をはっきり述べるというスピーチの基本中の基本についてです。<br/><br/>

            スピーチの内容を効果的に聞き手に伝えるためには、はっきりと言葉を発さなければなりません。「そんな基本的なこと？」と思うかもしれませんが、意外にこれができていない人が多いんです。<br/><br/>

            ぜひ一度自分のスピーチを録音して聞いてみてください。言葉が不明瞭なところが、たくさんあることに気がつくに違いありません。<br/><br/>

            自分の言葉を自分で「聞きにくい」と感じるということは、他の人はその何倍も聞きにくいと感じているということ。内容がどんなに良かったとしても、言葉が聞きにくいと感じさせてしまうと、スピーチの価値はぐんと下がってしまいます。<br/><br/>

            では、どうすれば言葉をはっきり述べることができるでしょうか？そのためには音声器官を正しく用いること、と言葉の構造を理解すること、がとても大切です。<br/><br/>

            <h3>＜音声器官を正しく用いる＞</h3>
            まず、音声器官としてすぐに思いつくのは、口や舌でしょう。口を大きく開けて喋るように意識しなさい、と子供の頃に言われたことがありますか？<br/><br/>

            でもスピーチの時に口を大きく開ける、ということを意識しすぎると、なんだか機械のような話し方になってしまい、自然さが失われてしまいます。<br/><br/>

            程よく口を開けて自然にスピーチするために意識するとよいのは、顎の筋肉と唇です。顎と唇がこわばってしまうと、音声が歯の間から押し出されるようになってしまいます。歯と歯の間から音が押し出されてしまうこと、それが口ごもった印象やゴニョゴニョ話している印象を与える要素なんです。<br/><br/>

            ですからスピーチをする前に、少しの時間でいいので口を縦に大きく開く練習をしてみてください。そうすると顎の筋肉の緊張がほぐれ、顎の筋肉の緩みができ、結果として口が大きく開きやすくなりますよ。<br/><br/>

            <h3>＜言葉の構造を理解する＞</h3>
            言葉をはっきり述べるために必要なもう一つのポイントは、言葉の構造を意識することです。<br/><br/>

            言葉がはっきり聞こえないのは、いくつかの単語をまとめて口早に話してしまうことにあります。上手く滑らかに喋ろうとしすぎるあまり、音節や字句をすっ飛ばしたり、繋げてしまったりすると、逆に言葉が不明瞭になってしまうというわけです。<br/><br/>

            それを避けるために、語句の構成を理解することが役に立ちます。語句の構成を理解するために、まず文節というものを意識しましょう。文節を見分けるために鍵となるのは、「ネ」です。<br/><br/>

            文の途中で「ネ」を入れてみましょう。例えばこんなふうにです。<br/><br/>

            例題：昨日はとてもいい天気だったので、愛犬と一緒に中央公園に散歩に行きました。<br/>
            ↓<br/>
            昨日は「ネ」とても「ネ」いい天気だったので「ネ」、愛犬と「ネ」一緒に「ネ」中央公園に「ネ」散歩に「ネ」行きました。<br/><br/>

            上のように、「ネ」で区切られている部分を文節と考え、その文節一つ一つを意識して発声しながらスピーチの練習をすると、言葉をはっきり話す大きな助けになります。<br/><br/>

            初めのうちは少しぎこちなく聞こえるかもしれませんが、練習を重ねていくうちに、自然な滑らかな話し方を習得できるようになっていきます。<br/><br/>

            <div style={styles.matome}>
            <h3>まとめ</h3>
            言葉をはっきり述べるために・・・。<br/>
            「顎と唇を意識して、口を大きく縦に開ける」<br/>
            「文節を意識し、言葉の構造を理解して練習する」<br/>
            </div>

            <br/><br/>

            <SubTitle>②休止を適切に入れる</SubTitle>
            上手にスピーチをする人は、一瞬の沈黙を大切にしています。つまり、声を発しない一瞬を「いつ・どのように作るか」をとても強く意識しているんだそうです。<br/><br/>

            もっとわかりやすく言えば、「間」の取り方を意識しましょう、ということ。「間が悪い」とか「間抜け」という言葉から連想されるように、休止のないスピーチは聞こえていても、なかなか頭に入ってきません。<br/><br/>

            逆に上手く休止を入れることができれば、スピーチは明快になり、伝えたいポイントが長く印象に残るものになります。<br/><br/>

            ではどんな点に注意しながら休止を取ればいいんでしょうか？主に、強調のための休止と、論題が変化するときの休止、を意識しましょう。<br/><br/>

            <h3>＜強調のための休止＞</h3>
            この強調のための休止は、一番言いたいポイントの前か後に入れる休止のことを言います。つまり「このスピーチで言いたいところはここだよ！」というメッセージを、休止を使って伝えるわけです。<br/><br/>

            休止を、伝えたいポイントの前に置くか、後に置くかによって得られる効果が異なる、ということを知っておけば、休止を適切に置く助けになります。<br/><br/>

            ポイントの前に休止を置くと、聞き手の期待を高めることができます。今から話されることが大切なんだ、という期待を持たせることで印象に残るスピーチができます。<br/><br/>

            逆に、ポイントの後に置く休止は、聞き手に考えさせ、情報を整理する時間を与え、記憶するための助けとなります。どちらに休止を置くとより効果的になるか、意識して選んでみましょう。<br/><br/>

            加えて、質問をした後も、適切な休止を置くことは大切です。スピーチですから、基本的に聞き手の参加はありませんよね。つまり、何かこちらが投げかけても答えが帰ってくるわけではありません。でも、質問されたら聞き手は頭の中では答えているものです。頭の中で答えるための休止を取ってあげること、これもとても大切なポイントです。<br/><br/>

            <h3>＜論題が変化するときの休止＞</h3>
            スピーチの論題が変わる時も、休止は必要です。一つのポイントから、もう一つ別のポイントに移る時には、この休止が必須となってきます。<br/><br/>

            聞き手が、スピーチのポイントを頭の中で整理出来ないうちに次の話を進んでしまうと、スピーチに着いていく気持ちがなくなってしまいます。一旦そうなってしまうと、せっかく内容が良くても、スピーチそのものの意味がなくなってしまうでしょう。<br/><br/>

            ですから、聞き手がついてこれるための休止をしっかり取りましょう。休止をしっかり取るためのポイントは、スピーチの量に注意することです。<br/><br/>

            休止のないスピーチをしてしまう人は、あまりに多くの要点を詰め込みすぎているため、文量が多い傾向があります。ですから、スピーチの時間と要点のバランスをしっかりとるようにしましょう。一般的に3分のスピーチに要点は１つだけで十分でしょう。それ以上だと、詰め込みすぎと感じられてしまいます。<br/><br/>

            <h3>＜その他の休止＞</h3>
            これはたまにしか使えないテクニックですが、話し始めに休止を置くこともできます。話し始めに、壇上に上がったら意識して10〜15秒ほどの休止を置いてみてください。<br/><br/>

            すると、聞き手は「あれ？どうしたのかな？」と考え始めます。その瞬間にスピーチを始めると、意識が強制的に話し手に向くようになるんです。<br/><br/>

            スピーチがマンネリ化してきたな、と感じる時につかってみてください。<br/><br/>

            <div style={styles.matome}>
            <h3>まとめ</h3>
            休止を適切に入れるために・・・。<br/>
            「強調のための休止をポイントの前後に入れる」<br/>
            「論題が変化する時に、考えを整理してもらうための休止を入れる」<br/><br/>

            注意点として、あまりに多くの休止を入れてしまうと、準備不足のスピーチのように感じられてしまいます。休止は程よく入れるように気をつけましょう。
            </div>
              <br/><br/>

            <SubTitle>③身振り</SubTitle>
            私たちは普段の会話をするときは無意識のうちに、身振りや手振りを加えて話しているものです。スピーチをする時も同じように、身振り手振りを加えることで、自然で親しみのこもったスピーチをすることができます。<br/><br/>

            逆に、なんの身振りもなく淡々とスピーチを続けてしまうと、無気力な感じが伝わってしまいます。生き生きとしたスピーチを行うために、身振りは必要不可欠なテクニックです。<br/><br/>

            有名な会社のCEOなんかも、ステージいっぱいに動き回って、身振りをたくさん使ってプレゼンしていますよね？それが人をひきつけるんです。ではスピーチのときどうすれば、効果的に身振りを使うことができるでしょうか？<br/><br/>

            身振りには大きく分けて、強調のための身振りと、描写のための身振りに分けられます。比較的簡単な描写のための身振りから説明しましょう。<br/><br/>

            <h3>＜描写のための身振り＞</h3>
            描写のための身振りとは、大きさや距離、位置や方向、数を表したり、ものの動きを表現したりするためのに良く使われます。<br/><br/>

            例えば「皆さんは・・」という言葉と一緒に両手を広げて、聞き手の方に差し伸べたり、「今日のスピーチのポイントは3つです」という時に3本指を示したりする、という比較的取り組みやすいものです。<br/><br/>

            慣れないうちは、スピーチの原稿の、方向や大きさを表す語句に注目しチェックを入れて置くと良いでしょう。どこでどんな身振りをするかイメージしてスピーチにのぞむようにしましょう。<br/><br/>

            最初はぎこちなく感じるかもしれませんし、少し気恥ずかしいかもしれませんが、そこは勇気を持って取り組んでみましょう。描写のための身振りを入れるのと入れないのとでは、スピーチの効果性が大きく異なってきますよ。<br/><br/>

            <h3>＜強調のための身振り＞</h3>
            描写のための身振りに慣れてきたら、今度は強調のための身振りに挑戦してみましょう。強調のための身振りを文字で説明するのは少し難しいのですが、自分が電話している場面をイメージするとわかりやすいかもしれません。<br/><br/>

            電話で自分がどうしても伝えたいことや、熱意を持って意見を述べている時、相手には見えていないのに、自然と身振りをしていることってありませんか？それが、強調のための身振りです。<br/><br/>

            強調のための身振りは、スピーチの内容に合わせたものにする必要があります。例えば、激励するためのスピーチであれば体を大きく使って気持ちを高めることができます。逆に、慰めるためのスピーチなら少し大人しめに、、その場の雰囲気を保った控えめな身振りをチョイスしましょう。<br/><br/>

            また、聞き手の人数によっても身振りのチョイスは変わってきます。少人数の聞き手しかいない場合、強調の身振りをしすぎると滑稽に見えてしまいますので注意が必要です。<br/><br/>

            つまり、強調のための身振りはスピーチの内容や聞き手の人数によって変わってくる、ということです。誰に、いつ、どこで、どんなスピーチをするかを考えて使うようにしましょう。<br/><br/>

            この身振りを上手に取り入れることができれば、もう一ついいことがあります。それは、自分自身も「乗ってくる」ということです。スピーチに活気が出てきて、生き生きした話ができますのでぜひ挑戦してみてください。<br/><br/>

            <div style={styles.matome}>
            <h3>まとめ</h3>
            身振りを上手に使うために・・・<br/>
            「まずは描写のための身振りを取り入れましょう」<br/>
            「強調のための身振りはその場の雰囲気に応じて使用しましょう」<br/><br/>

            究極を言えば、自分でも意識していないのに身振りをしてた、というのが最高の身振りです。そのために必要なのは、スピーチの内容を自分が完璧に理解しており、聞き手にとって益になる、という確信を持てるまで練り上げることでしょう。<br/><br/>

            最終的には、「意識せずとも出てくる身振り」を目指してください。
            </div>
              <br/><br/>

            <SubTitle>④顔の表情</SubTitle>
            身振りと同じほど顔の表情も話し手の感情を伝えるにとても効果的です。よく「目は口ほどに物を言う」と昔から言われますが、まさにその通り。口で発する言葉だけでなく、目を含めた顔の表情で感情や考えをスムーズに伝えることができます。<br/><br/>

            顔の表情は、喜怒哀楽を表現するのによく使われます。一言も発していないのに、感情を伝えることができるのが表情です。ですからスピーチの内容にあった表情をすると、聞き手の聴覚だけでなく視覚にも訴える、印象深いスピーチができます。<br/><br/>

            ではどうすれば、顔の表情の訓練をできるのでしょうか。基本的に顔の表情は、他の人から習うものではありませんよね？普通、親から「嬉しい時には口角を上げて笑いなさい」とか「悲しい時には悲しい顔をしなさい」なんて言われませんよね？表情は誰かに習うものではなく、自然と感情が顔に現れるものなのでしょう。人間の本能とも言えますね。<br/><br/>

            でも、そんなことを言ってしまうと、元も子もないのでアドバイスを一つ。表情筋を鍛えれば、より良い表情を表すことができるようになります。表情筋を鍛えるのにオススメしたいのは、「ウイスキー体操」です。<br/><br/>

            <ul>
              <li>歯を軽く閉じて唇を緩める</li>
              <li>唇だけを動かすことを意識して、ゆっくりウイスキーと言う</li>
              <li>10〜20回繰り返す</li>
            </ul>

            これを真剣に行うと、頬の筋肉がかなりきつくなってくるのがわかります。それは表情筋が鍛えられている証拠！スピーチの時にはその頬の筋肉を意識して行うと、良い表情を作ることができますよ。<br/><br/>

            <div style={styles.matome}>
            <h3>まとめ</h3>
            顔の表情を鍛えるために・・・<br/>
            「表情は言葉と同じほどものを語る、と言うことを意識する」<br/>
            「表情筋を鍛えるために、ウイスキー体操を行う」
            </div>
              <br/><br/>



            <SubTitle>⑤アイコンタクトを忘れない</SubTitle>
            スピーチの時、忘れてはならないのが聞き手とのアイコンタクトです。緊張しているので、ついつい原稿ばかりに目をやったり、同じ方向や人ばかりに目を向けたりしてしまいがちですよね？<br/><br/>

            でもそうしていると、話し手の誠実さや能力は疑われてしまいます。例えば、落語家やお笑い芸人が、観客ではなく台本ばかりをみて話していたらどう思いますか？想像してみてください。きっと、面白くないでしょう。<br/><br/>

            スピーチもそれと同じです。アイコンタクトを忘れてしまうと、内容は良くても、面白みのないスピーチになってしまうでしょう。逆に、アイコンタクトがしっかりできていると、スピーチはより一層生き生きした、確信のこもったものになっていきます。<br/><br/>

            でも、なかなかスピーチ中にアイコンタクトするのってハードル高いですよね？ではどうすれば、上手にスピーチ中に自然なアイコンタクトができるのでしょうか？<br/><br/>

            アイコンタクトをどうやってすればいいか？を考えるより、アイコンタクトをいつするか？に注目すると、上手にできるようになります。意識したいポイントは２つ、スピーチを始める前と、2文ごとです。<br/><br/>

            <h3>＜スピーチを始める前＞</h3>
            スピーチの前に、一言目を発する前に少し時間をとって、聞き手の幾人かとアイコンタクトをとることから始めてみてください。スピーチを始める前に、4〜5秒を置いて聞き手を意識的に見るようにしましょう。<br/><br/>

            こうすることで、聞き手はスピーチを聞くための心構えができますし、話し手もスピーチ前に聞き手を見ることで緊張を抑えることができます。お互いにスピーチ前に気持ちを整えることができるんです。<br/><br/>

            また、慣れてくると、最初の数秒で聞き手の様子を観察することもできるようになります。例えば、「あの人は今日調子が悪そうだな」とか「あの人は注意散漫だな」と言った具合にです。その情報に合わせて話し方や語調を最適なものに調整できるようになりましょう。<br/><br/>

            <h3>＜２文ごと＞</h3>
            スピーチ中は、2文ごとにアイコンタクトを取る相手を変えるように意識しましょう。例えば、Aさんを見ながら1、2文を話す。今度は少し離れたBさんを見て1、2文を話す。次は、また少し離れたC
            さんを・・という具合にです。<br/><br/>

            そうしていると、聞き手の反応を見ることができ、得た情報を元にその場の空気を読んだスピーチができるようになります。自分のスピーチを聞いて頷いていたり、真剣な表情をしていたり、または逆にあまりよく理解していない顔をしていたり、とさまざまな反応をキャッチするようにしましょう。<br/><br/>

            その反応に応じて内容を少し変えたり、語調を強めたり弱めたり、例えを挿入してみたり、とその場の空気に合わせたスピーチができるようになります。その場で瞬時にはできなくても、次回のスピーチに活かすことができますよね。<br/><br/>

            <div style={styles.matome}>
            <h3>まとめ</h3>
            聞き手とアイコンタクトを取るために・・・<br/>
            「スピーチを始める前に時間をとって聞き手を見る」<br/>
            「2文ごとに見る相手を変えながら反応をキャッチする」<br/><br/>

            注意点として、同じ人を見過ぎないこと。もし同じ人ばかりを見ていると、その人に気まずい思いをさせてしまいますし、その人以外は蚊帳の外になってしまいかねません。偏りなく、バランスよく見るようにしましょう。
            </div>
              <br/><br/>

            <SubTitle>⑥抑揚をつけたスピーチにする</SubTitle>
            スピーチに生気を吹き込むために絶対に欠かせないのが、抑揚です。抑揚のあるスピーチができれば、聞き手の感情に訴えることもできます。そして聞き手の感情に訴えることができれば「聞いたことを実践してみよう」という意欲を起こさせ、行動を促すこともできるようになるのです。<br/><br/>

            ですから抑揚はスピーチに欠かせないとても大切な要素と言えます。ではそもそも、抑揚とはなんでしょうか？簡単に言えば声の調子に変化をつけること、です。抑揚をつけたスピーチをするためには具体的にどうすれば良いのでしょうか？<br/><br/>

            色々なポイントがありますが今回は、声のボリューム、スピード、の2つのポイントに絞って抑揚について考えていきましょう。<br/>

            <h3>＜ボリューム＞</h3>
            声に抑揚をつけるため比較的取り組みやすいのが、この声のボリュームです。声を内容に合わせて適切に大きくしたり小さくしたりすることで、抑揚のついたスピーチができます。<br/><br/>

            具体的には、緊急感をあおったり、業績を伸ばしたり、注意喚起する場合にはボリュームを大きめにすることができます。逆に、慰めたり、悲しみを表したりする場合には小さめの声でスピーチするのが適切でしょう。<br/><br/>

            声のボリュームで抑揚を上手くつけるためには、声の大小よりも、ギャップを意識すると、抑揚のついたスピーチができます。<br/><br/>

            これはプロの歌手もよく使う方法です。歌い出しは意識して小さめのボリュームで歌い、サビに近づくにつれて徐々に声量を上げ、サビで一気に爆発させる、といった具合にです。歌い出しとサビのボリュームのギャップを大きくすることで、効果的に抑揚をつけるということ。<br/><br/>

            これをスピーチにも応用することができます。スピーチの序盤は少し意識して普段より小さめの声で、その後のポイントとなる箇所では大きめの声で発すると、抑揚のついたスピーチが簡単に出来上がります。結果、要点がくっきり鮮明になり印象と記憶に残るというわけです。<br/><br/>

            声のボリュームによって抑揚をつける方法は、初心者でも取り組みやすい方法ですから、ぜひ次のスピーチで取り入れてみてください。
            <br/><br/>
            <h3>＜スピード＞</h3>
            もうワンステップ上の抑揚のつけ方が、スピードに変化を持たせる、というもの。テンポに変化を持たせると言い換えることもできます。この抑揚のつけ方は、実は日常生活では無意識のうちにできている方法なんです。<br/><br/>

            一本調子で会話をする人っていませんよね？普段は意識していませんが、日常会話では興奮した時には早口になりますし、大切なことはゆっくり話しているものです。<br/><br/>

            ですが、スピーチとなるとなかなかこれができる人が少ないようです。やはり緊張しますし、ちゃんとミスなく話さなければと身構えてしまうからでしょう。<br/><br/>

            でもスピーチの時もきちんとポイントを抑えれば、上手にスピードに変化を持たせることができます。一般的にではありますが、興奮を伝える時やスピーチの要点以外の部分、つまり枝葉の部分は少し速めの話し方をしましょう。一方、スピーチの重要な部分、ポイント、記憶してほしい点はゆっくり話すことができます。<br/><br/>

            声のスピードの点でよくある失敗例は、一定の間隔で機械的に速くしたり、聞き手がついていけないほど突然にスピードを上げたりすることです。<br/><br/>

            こうした失敗をしないために、そして適度にスピードに変化を持たせて抑揚をつけるために何ができるでしょうか？スピーチを日常の会話に近づけること、を意識することが助けになります。<br/><br/>

            そのために最善の方法はスピーチを丸暗記しない、ということです。スピーチを準備する際、一字一句書き記された原稿を作るのではなく、言いたいこと伝えたいことを箇条書きにして、考えをまとめるようにしてみてください。<br/><br/>

            そうすれば自然と、日常会話のようにスピードを変えつつ抑揚に富んだスピーチになっていきます。<br/><br/>
            <div style={styles.matome}>
            <h3>まとめ</h3>
            抑揚をつけたスピーチのために・・・<br/>
            「声のボリュームを変えてみる」<br/>
            「スピードに変化をつけ、日常会話のようにスピーチをする」<br/>

            スピーチにおいて抑揚は、例えて言えば薬味のようなものです。わさびや唐辛子と言った薬味は、適切なものを適量入れることで、本来の素材の味を引き立てますよね？<br/><br/>

            同じように抑揚も、適切な時に適量使うことでスピーチをひきたてててくれるものです。使いすぎには、注意しましょう。
            </div>
            <br/><br/>


            <SubTitle>⑦会話的な話し方をマスターする</SubTitle>
            いくらスピーチだといっても、基本にたちかえれば人が人に何かを伝える、という点で普段の会話となんら変わりはありません。ですから、スピーチも日常会話のような会話的なスタイルで話せば、聞き手は落ち着いて話を聞くことができます。<br/><br/>

            会話的なスピーチにすることでくつろいだ気分で聞くことができますし、結果として聞いたことを実践してみよう、という気持ちにすることができます。ですから会話的な話し方をマスターすることは、より効果的なスピーチを行う上でとても大切なのです。<br/><br/>

            では会話的なスピーチをマスターするため、何をどうすればいいのでしょうか？すべきでないことと、すべきことに分けて考えていきましょう。<br/><br/>

            <h3>＜すべきでないこと＞</h3>
            <h4>・荒々しい言葉や下品な言葉</h4>
            一般的に会話で使われていてもスピーチで使うのにはふさわしくない言葉は避けるようにしましょう。例えば、「やばい」とか「マジで」「ガチで」「ムカつく」と言った言葉は意識して避けるようにしてください。そうしたことばを使うだけで、他は素晴らしくても、スピーチの品や価値が一気に下がってしまいます。<br/><br/>

            <h4>・同じ表現の繰り返し</h4>
            スピーチのポイントや、覚えて欲しい点を言う時には、強調するためにあえて繰り返すのはとても効果的なテクニックです。しかし無意味に、機械的に同じ表現を繰り返したり、言い回しが同じだったりすると会話的なスピーチとは程遠いものになってしまいます。<br/><br/>

            <h4>・不必要な言い直し</h4>
            言い直しを意味なくしてしまう人って、いますよね。例えば「今日はとてもいい天気、・・今日の天気はとてもよく、さわやかな風が、・・風がさわやかで気持ちのいい日となりました」とこんな感じです。別に間違っていないし、文法的にも変じゃないのに、ちょっと戻って言い直す、と言うことは避けるようにしましょう。<br/><br/>

            <h4>・言葉を多くし過ぎる</h4>
            言葉を多くしすぎて、スピーチの目指すところがわからなくなるようなことは避けましょう。わかりづらい例え話を入れるのも同じように避けてください。説明の必要な例え話であれば、入れない方がましです。要点やポイントほど簡潔に明快に述べましょう。<br/><br/>

            <h3>＜すべきこと＞</h3>
            <h4>・聞き手について考える</h4>
            自分がどう思われるか、このスピーチをしたらどんな評価になるかだろうかと考えるよりも、聞き手のことをまず第一に考えましょう。このスピーチで１つでも何かを得て欲しい、前向きになってもらいたい、元気付けたい、と言う思いを忘れないようにしてください。「私のスピーチってすごいだろう？」ではなく「本当にこのことを伝えたいんだ」という思いを持つようにすれば、自然と会話的になっていきます。<br/><br/>

            <h4>・原稿に頼らずに話す</h4>
            印刷された原稿をそのまま読むと、どうしても会話的な雰囲気は失われます。準備した原稿を読むのではなく、準備した自分の考えを伝える、という意識を持ってください。そして一文一文はなるべく短くしましょう。日常会話も短文の連続です。<br/><br/>

            <h4>・日常の会話の質を向上させる</h4>
            スピーチの時だけ、でなく普段から意識して会話の習慣を見直すようにしましょう。普段の積み重ねがスピーチにはどうしても表れてきます。日常会話の延長線上にスピーチを持ってくるようにしてください。<br/><br/>

            <div style={styles.matome}>
            <h3>まとめ</h3>
            会話的なスピーチにするために・・・<br/>
            「すべきことと、すべきでないことを意識する」<br/>
            「日常会話の質を高める」<br/><br/>

            ここまで、効果的なスピーチを行うためのテクニックをいくつかご紹介してきました。全部をいっぺんに行うことはできなくても、自分が「これだっ！」と思った点があれば、意識してトライしてみてください。<br/><br/>

            「話し上手は聞き上手」と昔からよく言われますよね。自分のスピーチだけでなく、他の人のスピーチもしっかり聞くようになさってください。良い点を探したり、はたまた反面教師にしたりと、誰のスピーチからも学べる点はいっぱいあるはずです。貪欲に吸収するようにしましょう。<br/><br/>
            </div>


            <h4>この記事が良いスピーチへの第一歩になれば嬉しく思います。</h4>

          </p>

        </article>

      </main>




    </Layout>
  )
  }
