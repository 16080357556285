import React from "react"
import {Link} from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

export default () => {
  return (
    <Layout>
      <SEO
        title="ページが見つかりません"
      />
      <section id="sec01">
        <h2>ページが見つかりません</h2>
        <div className="innerS">
          <div style={{textAlign: "center"}}>
            <Link to={`/`}>トップページに戻る</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}