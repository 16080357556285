import React from "react"
import Layout from "../components/Layout"
import {graphql,Link} from "gatsby"
import {nl2br} from "../functions"
import SEO from "../components/Seo"
import Card from "../components/Card"
import PrCard from "../components/PrCard"
import InfoCard from "../components/InfoCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight,faChevronCircleRight,faChevronCircleLeft,faLink } from '@fortawesome/free-solid-svg-icons'
import InfoCard1 from "../components/InfoCard1";
import {AdsenseItemBottom, AdsenseItemTop, AdsenseLinkUnit, AdsenseRelated} from "../components/Adsense";


export const query = graphql`
query($id:String!, $tagSlug:String!, $now: Date!) {
  contentfulNeta(id:{eq:$id}) {
    title
    slug
    tweet
    createdAt
    createdAtJP:createdAt(formatString:"YYYY/MM/DD")
    publishStartJP:publishStart(formatString:"YYYY/MM/DD")
    tags {
      id
      slug
      title
    }
    content {
      id
      content
    }
  }
  allContentfulNeta(
    limit:100
    filter: {
      tags: {elemMatch: {slug: {eq: $tagSlug}}},
      publishStart:{lt:$now}
    }
  ){
    edges{
      node{
        title
        slug
      }
    }
  }
}
`;



export default ({data,pageContext,location})=>{

  const {contentfulNeta,allContentfulNeta} = data;
  const content = contentfulNeta.content.content;

  let description = `【朝礼ネタ】 3分間スピーチ例文No${contentfulNeta.slug}:${contentfulNeta.title}`.slice(0,70);
// console.log(data);
//   const pageViews = data.pageViews && data.pageViews.totalCount || null;

  let related =  allContentfulNeta.edges.sort(()=> Math.random() - .5).slice(0,7);
 // console.log(related)


  return(
    <Layout>
      <SEO
        title={`【朝礼ネタ${contentfulNeta.slug}】 ${contentfulNeta.title} | 3分間スピーチの例文集`}
        description={description}
        pathname={location.pathname}
      />

      <main>
        <div style={{marginTop:20,textAlign:"center"}} className="3min-item-top">
          <AdsenseItemTop currentPath={location.pathname}/>
        </div>

        <Card
          basePath={`/`}
          slug={contentfulNeta.slug}
          title={contentfulNeta.title}
          content= {nl2br(content)}
          createdAtJP={contentfulNeta.createdAtJP}
          publishStartJP={contentfulNeta.publishStartJP}
          tags={contentfulNeta.tags}
          all={true}
        >


          <div style={{display:"table",width:"100%"}}>
            <div style={{display:"table-cell"}}>
              {pageContext.previous && (
                <Link to={`/neta/${pageContext.previous.slug}/`}><FontAwesomeIcon icon={faChevronCircleLeft}/>前のネタ
                  {/*<span style={{fontSize:11}}>(No.{pageContext.previous.slug})</span>*/}
                </Link>
              )}
            </div>
            <div style={{display:"table-cell",textAlign:"right"}}>
              {pageContext.next && (
                <Link to={`/neta/${pageContext.next.slug}/`}>次のネタ<FontAwesomeIcon icon={faChevronCircleRight}/>
                  {/*<span style={{fontSize:11}}>(No.{pageContext.next.slug})</span>*/}
                </Link>
              )}
            </div>
          </div>

          <div className="mt20">
            <span style={{fontSize:"smaller"}}>
            <h2><span style={{color:"purple"}}><FontAwesomeIcon icon={faLink}/></span> 関連する朝礼ネタ</h2>
            </span>

            {related.map((item)=>{
              const slice = (str) => str.length>16 ? item.node.title.slice(0,16)+"…" : str;
              return(
                <div className="mt10">

                  <Link to={`/neta/${item.node.slug}/`} style={{textDecoration:"none"}}><FontAwesomeIcon icon={faAngleRight}/> {slice(item.node.title)}</Link>
                </div>
              )
            })}
          </div>
          <div className="mt20" style={{textAlign:"center",lineHeight:"40px",marginTop:40}}>
            <Link  to={`/`} style={{ textDecoration:"_none"}}>
            <span style={{

            }}>
              <img style={{verticalAlign:"middle"}} width={32} src="/images/fukurou_150x150.jpg" alt="朝礼ネタトップ"/>
              トップページ
            </span>
            </Link>
          </div>
        </Card>

        <InfoCard1/>

        <div style={{marginTop:20,textAlign:"center"}} className="3min-bottom">
         <AdsenseItemBottom currentPath={location.pathname}/>
        </div>

        <div style={{marginTop:20,textAlign:"center"}} className="3min-link-unit">
          <AdsenseRelated currentPath={location.pathname}/>
        </div>

        <div className="mt20" style={{textAlign:"center",lineHeight:"40px",marginTop:40}}>
          <Link  to={`/`} style={{ textDecoration:"_none"}}>
            <span style={{

            }}>
              <img style={{verticalAlign:"middle"}} width={32} src="/images/fukurou_150x150.jpg" alt="朝礼ネタトップ"/>
              トップページ
            </span>
          </Link>
        </div>

        {/*<AdsenseRelated currentPath={location.pathname}/>*/}

        {/*<PrCard*/}
        {/*  title={*/}
        {/*  <>*/}
        {/*    <a href="https://px.a8.net/svt/ejp?a8mat=3BSSCN+76ZLP6+4K7E+60H7M" rel="nofollow">性格統計学で学ぶ！あなたのコミュニケーション傾向と具体的対策</a>*/}
        {/*    <img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3BSSCN+76ZLP6+4K7E+60H7M" alt=""/>*/}
        {/*  </>*/}
        {/*  }*/}
        {/*  link={*/}
        {/*    <>*/}
        {/*    <a href="https://px.a8.net/svt/ejp?a8mat=3BSSCN+76ZKXE+4K7E+669JM" rel="nofollow">公式サイトで詳細を見る</a>*/}
        {/*    <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3BSSCN+76ZKXE+4K7E+669JM" alt=""/>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*>*/}
        {/*    16年間延べ12万人の統計データをもとに体系化した「性格統計学」ベースの 「伝え方コミュニケーション検定」 です。<br/><br/>*/}

        {/*    自分ではしっかり伝えているつもりなのに、なぜか相手にわかってもらえない、*/}
        {/*    そんな経験はありませんか？<br/><br/>*/}

        {/*    コミュニケーション能力は就職や転職で有利になるだけではなく、家庭やプライベートでも生涯役に立つ汎用的なスキルです。*/}
        {/*    コロナ禍だからこそ、新しいことを始めてみませんか。<br/>*/}

        {/*</PrCard>*/}
      </main>






    </Layout>
  )
}