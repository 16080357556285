import React from "react"
import {Link} from "gatsby"
import SaveBtn from "./SaveBtn"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags,faCalendarAlt,faPencilAlt,faUniversalAccess} from '@fortawesome/free-solid-svg-icons'



export default ({basePath,slug,title,content,createdAtJP,publishStartJP,tags,index,all=false,children}) => {

  // const linkTo = `${basePath}neta/${slug}/`;
  // console.log(linkTo)
  return(
    <article className="content-card relative" key={index}>

      <Link to={`${basePath}neta/${slug}/`}>
        {all ? <h1>{title}</h1> : <h2>{title}</h2>}
      </Link>

      <span style={{fontSize:11,color:"#666"}}>
        <span style={{color:"blue",fontSize:16,cursor:"pointer"}}>
          <SaveBtn className="absolute" style={{top:15,right:15,fontSize:"20px"}} slug={slug}/>
        </span>
        　<span><FontAwesomeIcon icon={faPencilAlt} /> 朝礼ネタ{slug}</span>　
        <FontAwesomeIcon icon={faCalendarAlt} /> <span>{publishStartJP}</span>　

        <span>　
          <FontAwesomeIcon icon={faTags} />
          {tags && tags.slice(0,3).map((tag,index) => {
            return (
              <Link to={`/tag/${tag.slug}/`} key={index}>
                <span className="tag">{tag.title}</span>
              </Link>
            )
          })}
        </span>
      </span>


      {all && <span>
        <p className="content-text">{content}</p>


      </span>}

      {!all && <span>
      <p className="content-text">{content.slice(0, 80)}...</p>
      <div className="readmore">
        <Link  to={`${basePath}neta/${slug}/`}>続きを読む</Link>
      </div>
      </span>}

      {children && <span>
        {children}
      </span>}

    </article>

  )



}
