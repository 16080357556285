import React from "react"
import Layout from "../components/Layout"

import SubTitle from "../components/SubTitle"
import {Helmet} from "react-helmet"

export default ({data,location}) => {
  return (
    <Layout>
      <Helmet>
        <html lang="ja"/>
        <title>朝礼ネタ</title>
        <meta name="description" content="朝礼のネタ探しに困っている人の強い味方！"/>
        <link rel="canonical" href="https://3min-speech.jp/about/"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@3min_speech" />
        <meta name="twitter:title" content="朝礼ネタ" />
        <meta name="twitter:description" content="Webサイト、Androidアプリ、iOSアプリ、電子書籍で配信中です。" />
        <meta name="twitter:image" content="https://3min-speech.jp/images/app-pr.png"  />
      </Helmet>

      <main className="container">
        <article className="column">
          <SubTitle>サイトについて</SubTitle>
          <p>
          当サイト「朝礼ネタ 3分間スピーチの例文集」は、ネタ探しに困っている人向けの検索サービスです。<br/>
          国内最大規級の4500件以上のスピーチ例文を保有し、みなさまのお手伝いをします。<br/>
          <br/>
          各記事は短く話せば1分間スピーチ、長めに話せば3分間スピーチとして使えるボリュームとなっています。
          朝礼で一言スピーチを導入している会社は多く、ネタ探しに困っている方も多いと思いますので是非参考にしてください！
          </p>
        </article>

        <article className="column">
          <SubTitle>アプリ版</SubTitle>
          <img style={{width:"100%"}} src="/images/app-pr.png"/><br/>

          <div style={{display:"flex", flexDirection: "row"}}>
            <div style={{display:"flex", flexDirection: "column"}}>
              <a href="https://apps.apple.com/jp/app/id1550216211">
                <img width="180" alt="朝礼ネタiOS版" src="/images/btn-appstore.png"/>
              </a>
              <img class="qrcode" width="200" src="/images/qr-ios.png"/>
            </div>
            <div style={{display:"flex", flexDirection: "column"}}>
              <a href="https://play.google.com/store/apps/details?id=jp.speech_3min">
                <img width="180" src="/images/btn-googleplay.png"/>
              </a>
              <img class="qrcode" alt="朝礼ネタAndroid版" width="200" src="/images/qr-android.png"/>
            </div>
          </div>


        </article>




        <article className="column">
          <SubTitle>電子書籍のご紹介</SubTitle>
          <p>掲載中の例文から人気ネタを集めました。<br/>厳選ネタをじっくりと読みたい方にオススメです。</p>
          <p>Android、iPhoneでもkindleアプリをインストールすれば読めます。是非ともご覧ください！</p>
          <p style={{fontSize:12}}>★Unlimitedユーザーには全巻無料公開中です</p>

          <a target="_blank" rel="noreferrer"  href="https://www.amazon.co.jp/gp/product/B07F8N2YGZ/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B07F8N2YGZ&linkCode=as2&tag=imayounaoto-22&linkId=3f3cd84e5e48ba24e88334a9c7eb2c9f">
            <img alt="朝礼ネタ" border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=JP&ASIN=B07F8N2YGZ&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=imayounaoto-22" />
          </a>

          <img src="//ir-jp.amazon-adsystem.com/e/ir?t=imayounaoto-22&l=am2&o=9&a=B07F8N2YGZ" width="1" height="1" border="0" alt="朝礼ネタ" style={{border:"none !important", margin:"10 !important"}} />

          <a target="_blank"  rel="noreferrer" href="https://www.amazon.co.jp/gp/product/B07F8X6PBL/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B07F8X6PBL&linkCode=as2&tag=imayounaoto-22&linkId=297c107235be0a332ec426ab9d0e64d4">
            <img alt="朝礼ネタ" border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=JP&ASIN=B07F8X6PBL&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=imayounaoto-22" /></a>
          <img src="//ir-jp.amazon-adsystem.com/e/ir?t=imayounaoto-22&l=am2&o=9&a=B07F8X6PBL" width="1" height="1" border="0" alt="朝礼ネタ" style={{border:"none !important", margin:"10 !important"}} />
          <a target="_blank"  rel="noreferrer" href="https://www.amazon.co.jp/gp/product/B07F8SKG4D/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B07F8SKG4D&linkCode=as2&tag=imayounaoto-22&linkId=bea482f230a0ec06600bb20c9d4a377d">
            <img alt="朝礼ネタ" border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=JP&ASIN=B07F8SKG4D&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=imayounaoto-22" /></a><img src="//ir-jp.amazon-adsystem.com/e/ir?t=imayounaoto-22&l=am2&o=9&a=B07F8SKG4D" width="1" height="1" border="0" alt="朝礼ネタ" style={{border:"none !important", margin:"10 !important"}} />
          <a target="_blank"  rel="noreferrer" href="https://www.amazon.co.jp/gp/product/B07F9YYX1K/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B07F9YYX1K&linkCode=as2&tag=imayounaoto-22&linkId=effd706aebbaa9eb30137d0da25d4d74">
            <img alt="朝礼ネタ" border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=JP&ASIN=B07F9YYX1K&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=imayounaoto-22" /></a><img src="//ir-jp.amazon-adsystem.com/e/ir?t=imayounaoto-22&l=am2&o=9&a=B07F9YYX1K" width="1" height="1" border="0" alt="朝礼ネタ" style={{border:"none !important", margin:"10 !important"}} />
          <a target="_blank"  rel="noreferrer" href="https://www.amazon.co.jp/gp/product/B07FJMPSNJ/ref=as_li_tl?ie=UTF8&camp=247&creative=1211&creativeASIN=B07FJMPSNJ&linkCode=as2&tag=imayounaoto-22&linkId=24e1af146f6b78649a2b48208099b1a1">
            <img alt="朝礼ネタ" border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=JP&ASIN=B07FJMPSNJ&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=imayounaoto-22" /></a>
          <img src="//ir-jp.amazon-adsystem.com/e/ir?t=imayounaoto-22&l=am2&o=9&a=B07FJMPSNJ" width="1" height="1" border="0" alt="朝礼ネタ" style={{border:"none !important", margin:"10 !important"}} />

        </article>

        <article className="column">
          <SubTitle>お知らせ</SubTitle>
          <p>
            おかげさまで多数のお客様にご訪問いただいております。<br/>
            アクセス数と記事数の増加に伴い、快適にご覧いただけるようリニューアル中です。
          </p>
        </article>
      </main>




    </Layout>
  )
  }
