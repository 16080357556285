import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import {graphql} from "gatsby"
import {Link} from "gatsby"
import SubTitle from "../components/SubTitle"


export const query = graphql`
query MyQuery {
  allContentfulTag (
      sort: {fields: order, order: ASC}
  ){
    edges {
      node {
        title
        slug
        order
      }
    }
  }
}
`;






export default ({data,location}) => {


  const tags = data.allContentfulTag.edges;

  const tagA = tags.filter(({node})=> node.order.match(/^AA/));
  const tagB = tags.filter(({node})=> node.order.match(/^BB/));
  const tagC = tags.filter(({node})=> node.order.match(/^CC/));




  const Tag = ({title,slug}) => (
    <Link to={`/tag/${slug}/`}>
      <div style={{
        backgroundColor:"#ccc",
        margin:7,
        display:"inline-block",
        padding:"5px 10px"
      }}>
        {title}
      </div>
    </Link>
  );


  return (
    <Layout>
      <SEO
        title="カテゴリー検索"
        pathname={location.pathname}
      />

      <main className="container">

        <section className="column">
          <h1 style={{marginBottom:0,fontSize:20,width:'100%',textAlign:"center"}}>
            カテゴリー検索
          </h1>
          <div style={{textAlign:"center"}}>カテゴリー名を選択すると各記事の一覧を確認できます</div>
        </section>

        <section className="column">
          <SubTitle>一般カテゴリー</SubTitle>
          {tagA.map(({node},index)=> <Tag {...node} key={index}/>)}
        </section>
        <section className="column">
          <SubTitle>季節に関するカテゴリー</SubTitle>
          {tagB.map(({node},index)=> <Tag {...node} key={index} />)}
        </section>
        <section className="column">
          <SubTitle>職種に関するカテゴリー</SubTitle>
          {tagC.map(({node},index)=> <Tag {...node} key={index} />)}
        </section>

        <div style={{marginTop:20}}>
        <Link to={`/keywords/`}>キーワード</Link>
        </div>
      </main>



    </Layout>
  )
}
