import React,{useState,useEffect} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBeforeBook} from '@fortawesome/free-regular-svg-icons'
import { faBookmark as faAfterBook} from '@fortawesome/free-solid-svg-icons'

export default ({slug,style,className}) => {

  const [books,setBooks] = useState([]);


  useEffect(() => {
    let storageBooks =  JSON.parse(localStorage.getItem('books'));
    if(storageBooks===null){
      storageBooks = [];
    }
    setBooks(storageBooks);
  },[]);



  const save = () => {
    let newbooks;

    let storageBooks =  JSON.parse(localStorage.getItem('books'));
    if(storageBooks===null){
      storageBooks = [];
    }

    const index = storageBooks.indexOf(slug);
    if(index >= 0){
      storageBooks.splice(index,1);
    }else{
      storageBooks.push(slug);
      alert("お気に入りメニューに保存しました。")
    }

    newbooks = storageBooks;
    setBooks(newbooks);

    localStorage.setItem('books',JSON.stringify(storageBooks));
  };

  return (
    <span onClick={save} className={className} style={style}>
      {books && books.includes(slug) ? <FontAwesomeIcon icon={faAfterBook}/> : <FontAwesomeIcon icon={faBeforeBook}/>}
    </span>
  )
}
