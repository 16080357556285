import React from "react"
import {Link} from "gatsby"

export default ({isFirst,isLast,totalPages,currentPage,basePath}) => {

  return (
    <div className="pagenation">
      {!isFirst && (
        <div className="child prev">
          <Link  to={currentPage === 2  ? `${basePath}` : `${basePath}page/${currentPage - 1}/`} rel="prev">前へ</Link>
        </div>
      )}
      <div className="child">　{`${currentPage} / ${totalPages} `} ページ</div>
      {!isLast && (
        <div className="child next">
          <Link  to={`${basePath}page/${currentPage + 1}/`} rel="next">次へ</Link>
        </div>
      )}
    </div>
  )
}

