import React from "react"


export default ({text,totalCount,currentPage}) => {
  return(
    <div>
    <h1 className="main-h2">{text}</h1>
    <div className="main-h3">{totalCount}件中 {currentPage}ページ目</div>
    </div>
  )


}