import React from "react"
import {useStaticQuery,graphql,navigate} from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
query {
  allContentfulTag (
      sort: {fields: order, order: ASC}
  ){
    edges {
      node {
        title
        slug
        order
      }
    }
  }
}
  `);


  const tags = data.allContentfulTag.edges;
  const tagA = tags.filter(({node})=> node.order.match(/^AA/));
  const tagB = tags.filter(({node})=> node.order.match(/^BB/));
  const tagC = tags.filter(({node})=> node.order.match(/^CC/));

  const Option = ({slug,title}) => {
    return(
      <option
        value={slug}
      >{title}</option>
    )
  };

  const handleSelect = (e) => {
    const slug = e.target.value;
    navigate(`/tag/${slug}/`);
  };



  const keywords1 = () => {
    let date = new Date();
    const day = date.getDate();
    const month1 = date.getMonth() + 1;
    const month2 = date.getMonth() + 2;

    let result = [];


    const map = {
      1 : "january",
      2 : "february",
      3 : "march",
      4 : "april",
      5 : "may",
      6 : "june",
      7 : "july",
      8 : "august",
      9 : "september",
      10: "october",
      11: "november",
      12: "december"
    }

    const data = {word:month1+"月",url:`/tag/`+map[month1]+`/`}
    result.push(data)

    if(day > 25){
      const data = {word:month2+"月",url:`/tag/`+map[month2]+`/`}
      result.push(data)
    }

    if(month1 == 9 && day < 10){
      result.push({word:"パラリンピック",url:`/tag/olympics/`})
    }

    return result;
  }


  const keywords2 = [
    {word:'社会',url:'/tag/society-and-economy/'},
    {word:'心理',url:'/tag/psychology-mentality/'},
    {word:'商品',url:'/tag/product-service/'},
    {word:'メディア',url:'/tag/media-news/'},
    {word:'テクノロジー',url:'/tag/it-technology/'},
    {word:'資産運用',url:'/tag/investment-asset-management/'},
    {word:'スポーツ',url:'/tag/sports/'},
    {word:'芸能',url:'/tag/performing-arts/'},
    {word:'ビジネス',url:'/tag/business/'},
    {word:'グルメ',url:'/tag/gourmet/'},
    {word:'ライフハック',url:'/tag/lifehack/'},
    {word:'安全',url:'/tag/safety/'},
    {word:'家族',url:'/tag/family-pet/'},
    {word:'ことわざ',url:'/tag/proverb-an-event/'},
    {word:'名言',url:'/tag/quotations-maxims/'},
    {word:'尊敬',url:'/tag/respected-person/'},
    {word:'規則',url:'/tag/rules/'},
    {word:'法則',url:'/tag/law/'},
    {word:'読書',url:'/tag/reading-book-reviews/'},
    {word:'地域',url:'/tag/region-information/'},
    {word:'経営',url:'/tag/management/'},
    {word:'教育',url:'/tag/education/'},
    {word:'趣味',url:'/tag/hobby/'},
    {word:'自己啓発',url:'/tag/self-enlightenment/'},
    {word:'資格',url:'/tag/qualification-acquisition/'},
    {word:'目標',url:'/tag/goal/'},
    {word:'時間',url:'/tag/time-management/'},
    {word:'体調',url:'/tag/health-management/'},
    {word:'効率化',url:'/tag/efficiency/'},
    {word:'食事',url:'/tag/meal/'},
    {word:'睡眠',url:'/tag/sleep/'},
    {word:'健康',url:'/tag/health/'},
    {word:'運動',url:'/tag/exercise/'},
    {word:'コミュ',url:'/tag/communication/'},
  ];



  const margeKeywords = () => {
    const selected = keywords2.slice().sort(() => Math.random() - 0.5).slice(0, 6);
    let keyword1 = keywords1();
    const result = keyword1.concat(selected);

    return result;
  }



  return(
    <div className="header-main">
      <div className="header-content">
        <h1 className="header-h1">朝礼ネタ検索</h1>



        <form id="form01" method="get" action="search">
          <input id="input01" type="text" name="q" minLength={2} placeholder="検索ワードを入力してください" required={true}/>
          <input id="submit01" type="submit" value="検索"/>
        </form>

        <p className="search-word">
          {margeKeywords().map((item,index)=>
            <a key={index} href={item.url}>{item.word}</a>
          )}
        </p>
        <select onChange={handleSelect} className="search-category">
          <option value="">一般カテゴリから探す</option>
          {tagA.map(({node},index)=> <Option {...node} key={index}/> )}
        </select>
        <select onChange={handleSelect} className="search-category">
          <option value="">季節から探す</option>
          {tagB.map(({node},index)=> <Option {...node} key={index}/> )}
        </select>
        <select onChange={handleSelect} className="search-category">
          <option value="">職種から探す</option>
          {tagC.map(({node},index)=> <Option {...node} key={index}/> )}
        </select>

      </div>

    </div>
  )

}